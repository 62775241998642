@function addTransition($property) {
  @return $property 200ms ease-in;
}

.main-container {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;

  .navbar-container {
    z-index: 4;
    transition: addTransition(all);
  }

  .navbar-container-notCollapsed {
    width: 250px;
  }

  .navbar-container-collapsed {
    width: 90px;
  }

  .main-content {
    transition: addTransition(padding-left);
    height: 100vh;

    .header-container {
      position: relative;
      z-index: 2;
      background-color: #f4f5f5;

      .header {
        .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 21px;
          color: #383941;
        }

        .title-long {
          font-weight: 600;
          font-size: 20px;
          line-height: 21px;
          color: #383941;
        }
      }
    }

    .content-container {
      height: calc(100vh - 90px);
      overflow-y: auto;
      // margin-top: 100px;
    }
  }

  .main-content-notCollapsed {
    width: calc(100vw - 250px);
  }

  .main-content-collapsed {
    width: calc(100vw - 90px);
  }
}

.heading-text-container {
  overflow: hidden;
  height: 25px;
}

.heading-text-list {
  list-style-type: none;
  position: relative;
  transition: top 2s ease-out;
}

.heading-text {
  height: 50px;
  line-height: 25px;
  text-align: start;
}

.heading-text-color {
  background: linear-gradient(90deg, #7318aa -6.34%, #d38254 49.46%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 995px) {
  .heading-text {
    text-align: end;
  }
}

@media (max-width: 991px) {
  .main-container {
    .main-content {
      .header-container {
        .header {
          .title-long {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .main-container {
    .navbar-container {
      display: none !important;
    }
    .navbar-container-notCollapsed {
      left: -250px;
    }
    .navbar-container-collapsed {
      left: -130px;
    }
    .main-content-notCollapsed,
    .main-content-collapsed {
      width: 100%;
    }
    .header {
      .open-icon {
        display: block !important;
      }
      .collapse-icon {
        display: none !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .main-container {
    .main-content {
      .header-container {
        .header {
          .title-long {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.notificationBadge {
  width: 5px;
  height: 5px;
  background-color: #d84560;
  border-radius: 50%;
  padding: 10px;
  font-size: 10px;
  position: absolute;
  top: -2px;
  right: -3px;
  color: #f4f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 28px solid #ffffff;
  position: absolute;
  right: -18px;
  transform: translateY(-500%);
}

.triangle.open {
  transform: translateY(0);
}
