@media (max-width: 824px) and (min-width: 769px) {
  .activeTab,
  .billingSpaceDropDown {
    width: 100% !important;
    margin-top: 16px !important;
  }
}

.userDashboardOverflow {
  overflow-y: auto;
  height: calc(100vh - 200px);
}

@media (max-width: 991px) {
  .userDashboardOverflow {
    height: calc(100vh - 240px);
  }
}
