@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import "~react-toastify/dist/ReactToastify.min.css";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;600&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-bg: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #2f2a89;
}

.modal-lg {
  max-width: 730px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-underline:hover {
  text-decoration: underline;
}

.cursor-disabled {
  cursor: not-allowed;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: rgb(128, 128, 128);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(66, 66, 66);
  border-radius: 20px;
}

.error {
  font-size: 12px;
  color: #d74242;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.jakarta {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.bg-red {
  background-color: #d74242;
}

.table {
  --bs-table-bg: none !important;
}

.css-vxjj9a-control {
  height: auto !important;
}

.css-yuyjqz-control {
  height: auto !important;
}

.today-grid-style {
  background-color: #ffd7d7;
  border-radius: 6px;
}

.Toastify__toast-container {
  z-index: 100000 !important;
}

.rc-tooltip {
  z-index: 10000;
}

.dArrow {
  transition: transform 200ms ease-in;
}

.instruction-close {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 100%;
}

.hierarchy-overflow {
  overflow-y: auto;
  height: calc(100vh - 170px);
}

.filtered-devices-list {
  overflow-y: auto;
  height: calc(100vh - 150px);
}

@media (max-width: 1199px) {
  .hierarchy-overflow {
    height: calc(100vh - 190px);
  }

  .filtered-devices-list {
    height: calc(100vh - 210px);
  }
}

@media (max-width: 991px) {
  .hierarchy-overflow {
    height: calc(100vh - 260px);
  }
  .filtered-devices-list {
    height: calc(100vh - 270px);
  }
}

@media (max-width: 508px) {
  .hierarchy-overflow {
    height: calc(100vh - 310px);
  }
  .filtered-devices-list {
    height: calc(100vh - 310px);
  }
}

.grid-overflow {
  overflow-y: auto;
  height: calc(100vh - 240px);
}

.grid-overflow-second {
  overflow-y: auto;
  height: calc(100vh - 180px);
}

.space-container,
.controller-space-container,
.schedule-log-view-container {
  background: #ffffff;
  box-shadow: 0px 24px 120px rgba(38, 51, 77, 0.05);
  border-radius: 8px;
  transition: height 200ms ease-in;
  border: 1.5px solid #f5f6f7;
  .pc-header {
    label {
      font-size: 16px;
      color: #383941;
    }
  }
}
